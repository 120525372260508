<template>
  <div class="container not-found">
    <NotFoundPageIcon />
    <p class="f-medium-grey f-sbold">Error 404. Page Not Found :(</p>
    <p class="f-medium-grey f-sbold">
      Go to
      <router-link class="f-medium-orange " :to="{ name: 'MainPage' }">
        Homepage
      </router-link>
    </p>
  </div>
</template>

<script>
import { NotFoundPageIcon } from '@/svg';

export default {
  name: 'PageNotFound',
  components: {
    NotFoundPageIcon
  }
}
</script>